@import 'reset';
@import 'variables';
@import 'mixins';

@import 'header.less';


html, body{
   line-height:1.66em;
   font-size:15px;
   color:@textColor;
   background:@white2;
   font-family:@bodyFont;
   font-weight:400;
   /*
   position:fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   overflow:hidden;
   */
   -webkit-text-stroke: 0px;
   -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
	font-smoothing: antialiased;
   text-rendering: optimizeLegibility;
}
.row{
   width:100%;
   max-width:1140px;
   margin:0 auto;
   padding:0 24px;
   position:relative;
   @media only screen and (max-width:960px){
      padding:0 12px;
   }
   &.medium {
	  max-width: 1026px;
   }
   &.small{
      max-width:840px;
   }
   &.fullheight{
      height:100%;
   }
}


h1, h2, h3{
   color:@dark;
   line-height:1.4em;
}
h1{
   font-size:36px;
   font-family:@headFont;
   font-weight:800;
   text-transform:uppercase;
   letter-spacing:0.06em;
   position:relative;
   @media only screen and (max-width:500px){
      font-size:28px;
   }
   &.title:after{
      content:"";
      position:absolute;
      bottom:0;
      left:0%;
      .translate(0%, -50%);
      width:80px;
      background:@yellow;
      height:2px;
   }
}
h2{
   font-size:24px;
   font-family:@headFont;
   font-weight:800;
   text-transform:uppercase;
   letter-spacing:0.03em;
   position:relative;
   padding-bottom:12px;
   margin-bottom:24px;
   &.big{
      font-size:36px;
   }
   &.center{
      text-align:center;
   }
   &.title{
      &:after{
         content:"";
         position:absolute;
         bottom:0;
         left:0%;
         .translate(0%, -50%);
         width:80px;
         background:@yellow;
         height:2px;
      }
      &.center{
         &:after{
            left:50%;
            .translate(-50%, -50%);
         }
      }
   }
}
h3{
   font-size:18px;
   font-family:@headFont;
   font-weight:800;
   text-transform:uppercase;
   letter-spacing:0.03em;
}
h4{
   color:@black;
   font-weight:600;
   font-size:1em;
}
h5{
   font-style:italic;
   text-align:left;
}

p{
   line-height:1.66em;
   margin-bottom:1em;
}


#page__container{
   height: calc(~"100% - 64px");
   height: calc(~"100vh - 64px");
   width:100%;
   /* 
   position:absolute;
   top:0;
   left:0;
   overflow-y: scroll;
   */
   -webkit-overflow-scrolling: touch;
   .blur(0px);
   .transition(filter 1s 0s @easeOutExpo);

   &.blur{
      .blur(3px);
   }
   .content__container{
      position:relative;
      min-height:100vh;
      .plus-bg{
         content:"";
         position:absolute;
         top:0;
         bottom:0;
         right:0;
         left:0;
         background-image:url(images/plus.svg);
         background-size:none;
         background-repeat:repeat;
         background-position:center center;
         z-index:-1;
         opacity:0.15;
      }
   }
}


.frontpage{
   #page-title{
       height: ~"calc(100vh - 64px)";
       margin-top: 64px;
      .bg__container{
          bottom: 0;
      }
      .text__container{
         max-width:600px;
         margin-bottom:48px;
         h1{
            text-align:center;
            width:100%;
            float:none;
            padding-right:0;
         }
         p{
            float:none;
            width:100%;
            text-align:center;
            font-size:16px;
            font-weight:600;
            padding-left:40px;
            padding-right:40px;
            @media only screen and (max-width:500px){
               font-size:15px;
               padding-left:0;
               padding-right:0;
            }
         }
         .button-b{
            padding:6px;
            font-weight:600;
         }
      }
      
      .social-buttons {
          display: none;
      }
      
      .mouse-scroll {            
            width: 30px;
            height: 50px;
            margin: 0 auto;
            border-radius: 10px;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 96%;
            transform: translate(-50%, -50%);
            
            &:before {                
                content: "";                
            }
        }
   }  
   
}

#page-title{
   position:relative;
   width:100%;
   margin-bottom:~"calc(50px)";
   &.bg-rood{
      .bg__container{
         .main-bg .container{
            &:after{
               background:@yellow;
            }
         }
         .bg-bg{
            background:@dark;
         }
      }
   }
   .bg__container{
      position:absolute;
      top:0px;
      left:0px;
      right:0px;
      bottom:-5vh;
      overflow:hidden;
      .translateZ(0);
      .bg-bg{
         background:@yellow;
         top:0px;
         left:40%;
         right:-1.5vw;
         bottom:0;
         position:absolute;
         background-size:cover;
         background-position:center center;
         transform-origin:0 ~"calc(100% - 360px)";
         /*
         border-radius:0 0 0px 72px;
         overflow:hidden;
         transform:rotate(-4deg) skewX(10deg);
         */
      }
      .main-bg{
         background:@dark;
         top:0px;
         left:-2vw;
         right:-12vw;
         bottom:5vh;
         position:absolute;
         //clip-path:polygon(0% 0%, 100% 0%, 100% ~"calc(100% - 6vw)", 0% 100%);
         //background-image:url(images/bg1.jpg);
         background-size:cover;
         background-position:center center;
         transform-origin:0 ~"calc(100% - 360px)";
         /*
         border-radius:0 0 0px 72px;
         overflow:hidden;
         transform:rotate(-4deg) skewX(10deg);*/
         @media only screen and (max-width:767px){
            left:-12vw;
            right:-20vw;
         }
         .container{
            position:absolute;
            top:0;
            right:0;
            bottom:-10vh;
            left:-5vw;
            /*transform:rotate(4deg) skewX(-10deg);*/
            video{
               position:absolute;
               top:0;
               left:0;
               width:100%;
               height:120%;
               object-fit:cover;
            }
            .bg{
               position:absolute;
               top:0;
               right:0;
               bottom:0;
               left:0;
               background-size:cover;
               background-position: center 50%;
               //.grayscale(80%);
            }
            &:after{
               content:"";
               position:absolute;
               top:0;
               right:0;
               bottom:0;
               left:0;
               background: #0B2055;
               background: fade(#0B2055, 20%);
               opacity:0.8;
            }
         }
         &:after{
            content:"";
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            opacity:0.4;
            background-size:cover;
            z-index:2;
         }
      }
   }
   .play-button{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;      
      transform: translate(-50%, -50%);
      
      @media only screen and (max-width:1140px){
         bottom:-3vw;
         right:10vw;
      }
      @media only screen and (max-width:767px){
         bottom:-5vw;
      }
      @media only screen and (max-width:560px){
         position:relative;
         /*margin:0 auto;*/
         right:0;
         bottom:0;
         /*.translate(0, -80px);*/
      }
   }
   .text__container{
      width:100%;
      max-width:1140px;
      position:relative;
      display:inline-block;
      //padding-top:~"calc(@{headerHeight} + 90px)";
      //padding-top:~"calc(3vw + 90px)";
      padding:~"calc(3vw + 90px)" 60px ~"calc(3vw + 90px)";
      left:50%;
      .translate(-50%, 0);
      z-index:9;
      //background:@white;
      @media only screen and (max-width:600px){
         padding-left:24px;
         padding-right:24px;
      }
      h1{
         color:@white;
         text-align:left;
         margin-bottom:32px;
         padding-bottom:8px;
         position:relative;
         width:50%;
         padding-right:24px;
         float:left;
         @media only screen and (max-width:767px){
            width:100%;
            max-width:520px;
            float:none;
            margin-left:auto;
            margin-right:auto;
         }


      }
      p{
         width:50%;
         float:left;
         font-size:18px;
         color:@white;
         text-align:left;
         font-weight:600;
         opacity:1;
         padding-left:24px;
         line-height:1.8em;
         @media only screen and (max-width:767px){
            width:100%;
            float:none;
            padding-left:0;
            max-width:520px;
            margin-left:auto;
            margin-right:auto;
         }

      }
      .button-b{
         color:@white;
         font-weight:600;
      }
      .button__container{
         width:100%;
         text-align:center;
         margin-top:24px;
         .button-b{
            margin-right:24px;
            &:last-child{
               margin-right:0;
            }
         }
      }
   }
   .social-buttons{
      position:absolute;
      top:50%;
      left:12px;
      .translate(0, -50%);
      @media only screen and (max-width:500px){
         display:none;
      }
      li{
         float:none;
      }
   }
}


.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
