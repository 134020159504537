header#header{
   position:fixed;
   top:0;
   left:0;
   right:0;
   height:@headerHeight;
   z-index:20;
   background: @darkBlue;
   &:before{
      content:"";
      position:absolute;
      left:0;
      right:0;
      bottom:0;
      top:0;
      background:@white;
      opacity:1;
      box-shadow:0px 0px 60px 5px rgba(0,0,0,0.1);
      .transition(all 1s 0s @easeOutExpo);
   }
   &:after{
      content:"";
      height:0;
      width:100%;
      position:absolute;
      top:0;
      left:0;
      background:@dark;
      z-index:-1;
      opacity:0;
      .transition(height 0s 1s @easeOutExpo, opacity 1s 0s @easeOutExpo);
   }
   &.dark{
      &:before{
         opacity:0;
         .transition(opacity 0.1s 0s @easeOutExpo);
      }
      &:after{
         opacity:0.9;
         height:100vh;
         .transition(height 0s 0s @easeOutExpo, opacity 1s 0s @easeOutExpo);
      }
   }
   &.transparent{
      &:before{
         opacity:0;
      }
      .logo__container img.logo#logo{
         display:none;
      }
      .logo__container img.logo#logo2{
         display:block;
      }
   }
   
   .row {
       text-align: center;
       .social-buttons {
           position: absolute;           
           transform: translate(0, 12%);
           
           @media only screen and (max-width:1140px){
                left: 30px;
           }
           @media only screen and (max-width:767px){
                left: 0;
           }
           @media only screen and (max-width:560px){
              left: 0;
           }
       }
   }
   
   .logo__container {
        height:@headerHeight;
        display:inline-block;
        img.logo {
           padding:12px 0 12px 0;
           height:@headerHeight;
           &#logo{
              display:block;
           }
           &#logo2{
              display:none;
           }
        }
     }
   
   nav#main-nav{
      position:absolute;
      height:0;
      width:100%;
      top:@headerHeight;
      left:0;
      z-index:-3;
      overflow:hidden;
      .transition(all 0s 0.4s);
      &.open{
         z-index:3;
         height:~"calc(100vh - @{headerHeight})";
         .transition(all 0s 0s);
         ul>li{
            opacity:1;
            &:nth-child(1){.transition-delay(0.2s);}
            &:nth-child(2){.transition-delay(0.25s);}
            &:nth-child(3){.transition-delay(0.3s);}
            &:nth-child(4){.transition-delay(0.35s);}
            &:nth-child(5){.transition-delay(0.4s);}
            &:nth-child(6){.transition-delay(0.45s);}
            &:nth-child(7){.transition-delay(0.5s);}
            &:nth-child(8){.transition-delay(0.55s);}
            &:nth-child(9){.transition-delay(0.6s);}
            &:nth-child(10){.transition-delay(0.65s);}
            &:nth-child(11){.transition-delay(0.7s);}
            &:nth-child(12){.transition-delay(0.75s);}
         }
      }
      ul{
         position:absolute;
         left:50%;
         top:50%;
         .translate(-50%, -50%);
         width:90%;
         max-width:400px;
         user-select:none;
         li{
            width:100%;
            display:block;
            opacity:0.000001;
            .transition(all 0.8s 0s ease);
            a{
               padding:0.8em 1em;
               line-height:1.2em;
               width:100%;
               display:block;
               text-align:center;
               color:@white;
               text-decoration:none;
               font-size:1.2em;
               font-family:@headFont;
               position:relative;
               cursor:pointer;
               @media only screen and (max-height:600px){
                 padding:0.4em 1em;
               }
               &:hover{
                  &:after{
                     width:92px;
                     opacity:1;
                  }
               }
               &:after{
                  content:"";
                  position:absolute;
                  left:50%;
                  bottom:8px;
                  .translate(-50%, 0);
                  height:2px;
                  width:24px;
                  opacity:0;
                  background:@yellow;
                  .transition(all 0.8s 0s @easeOutExpo);
               }
            }
         }
      }
   }
   #responsive-lines{
      position:absolute;
      top:0;
      width:60px;
      right:24px;
      height:@headerHeight;
      cursor:pointer;
      @media only screen and (max-width:900px){
         right:12px;
      }
      &:after{
         content:"";
         position:absolute;
         top:0;
         left:-40px;
         right:-40px;
         bottom:-40px;
         z-index:-1;
         pointer-events: none;
      }
      &.open{
         .mouse-hover{
            -webkit-transform:translate(-50%, -50%) scale(0.8);
            transform:translate(-50%, -50%) scale(0.8);
            opacity:1;
            @media only screen and (max-width:767px){
               -webkit-transform:translate(-50%, -50%) scale(0.6);
               transform:translate(-50%, -50%) scale(0.6);
            }
         }
         .line{
            height: 3%;
            background:transparent;
            @media only screen and (max-width:767px){
               height:2px;
            }
            &#line-1{
               width:25px;
               transform:translate(-13px, 0px) rotate(45deg);
               border: 0 solid @white2;
               background: @white2;
               box-shadow: 0 -1px 1px rgba(0,0,0, .2);
            }
            &#line-2{
               opacity:0;
            }
            &#line-3{
               width:25px;
               transform:translate(-13px, 0px) rotate(-45deg);
               border: 0 solid @white2;
               background: @white2;
            }
         }
      }
      &:hover{
         .mouse-hover{
            transform:translate(-50%, -50%) scale(0.8);
            opacity:1;
            @media only screen and (max-width:767px){
               transform:translate(-50%, -50%) scale(0.6);
            }
         }

      }
      .mouse-hover{
         width:80px;
         height:92px;
         opacity:0;
         transform:translate(-50%, -50%) scale(0.5);
         .mouse-hover-inner{
            .transition(all 0.05s 0s ease);
         }
      }
      .line{
         background:@white2;
         height:2px;
         position:absolute;
         width:32px;
         left:50%;
         top:50%;
         .transition(all 0.2s 0s ease);
         .transform-origin(50% 50%);
         &:after{
            content:"";
            position:absolute;
            /*border-bottom:solid 2px @dark;*/
            left:0;
            right:0;
            bottom:-2px;
         }
         &#line-1{
            transform:translate(-16px, -8px);
            width:24px;
         }
         &#line-2{
            transform:translate(-16px, 0px);
            width:32px;
         }
         &#line-3{
            transform:translate(-16px, 8px);
            width:16px;
         }
      }
      
      &:before {
            content: 'menu';
            color: @white;
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%);
            text-transform: uppercase;
            font-weight: bold;
      }
   }

}
