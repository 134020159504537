
@h1Size:               36px;
   @h1SizeSmall:          36px;
      @h1SizeSmaller:          28px;
@h2Size:               32px;
   @h2SizeSmall:          28px;
@h3Size:               1.2em;
   @h3SizeSmall:          1.1em;


@white:                #FFFFFF;
@white2:               #F4F4F4;
@textColor:            #555555;
@dark:                 rgba(32,59,127,1);//#2F33B0; //#021427;
@softBlue:             #6686B6;
@black:                #000;

@yellow:               rgba(218,5,45,1);//#FF6364; //#FFE875;



@headFont:    "Exo", "Open Sans", sans-serif;
@bodyFont:    "Open Sans", "Arial", sans-serif;

@headerHeight:         64px;
@headerHeightSmall:    60px;



@boxShadow: 0px 12px 30px rgba(216, 172, 136, 0.15);
@boxShadow2: 0px 0px 18px -6px @yellow;


@ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
@easeIn: cubic-bezier(0.690, 0.235, 0.920, 0.410);
@easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);

/* Custom colors */
@darkBlue : #203b7f;
@red: #da052d;
